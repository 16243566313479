<template>
  <div id="app">
    <div class="app-content">
      <Header></Header>
      <router-view></router-view>
      <Footer></Footer>
    </div>
    <div class="app-background"></div>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
#app {
  height: 100%;
}
#app .app-content {
  padding-bottom: 60px;
}
</style>
