import Vue from 'vue'
import App from './App.vue'
import 'jquery'
// import VueI18n from 'vue-i18n'
import './assets/css/index.scss'
import './assets/css/theme.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
Vue.config.productionTip = false

// Vue.use(VueI18n)

import router from '@/routers/index'

// const i18n = new VueI18n({
//   locale: localStorage.getItem("language"), // 语言标识
//   messages: {
//     'zh': require('./common/lang/zh'),
//     'en': require('./common/lang/en')
//   }
// })
new Vue({
  render: h => h(App),
  // i18n,
  router,
}).$mount('#app')
