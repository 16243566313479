<template>
  <div class="header">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-dark">
        <div class="navbar-brand">
          <a href="/" class="logo">
            <img
              src="@/assets/cover.png"
              class="logo-left d-inline-block align-top"
              alt=""
            />
            <img
              v-if="switchType == 'on'"
              src="@/assets/logo.png"
              class="d-inline-block align-top logo-right"
              alt=""
            />
            <img
              v-if="switchType == 'off'"
              src="@/assets/logo-dark.png"
              class="d-inline-block align-top logo-right"
              alt=""
            />
            <!-- <h3 class="logo-title">DINOC</h3> -->
          </a>
        </div>
        <button
          :style="switchType == 'off' ? 'background:rgba(0, 0, 0, 0.7)' : ''"
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <a class="nav-item nav-link actived" href="/#intro">About</a>
            <a class="nav-item nav-link" href="/#buy">Buy</a>
            <a class="nav-item nav-link" href="#footer">Help</a>
            <!-- <a class="nav-item nav-link" href="/airdrop">Airdrop</a> -->
            <a
              class="nav-item nav-link wallet"
              href="https://metamask.io/"
              target="_blank"
              >Connect to a wallet</a
            >
            <!-- <a class="nav-item nav-link" href="#intro">{{ $t("header.link1") }}</a>
            <a class="nav-item nav-link" href="#buy">{{ $t("header.link2") }}</a>
            <a class="nav-item nav-link" href="#help">{{ $t("header.link3") }}</a> -->
            <!-- <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ language == "zh" ? "简体中文" : "English" }}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <button
                  v-if="language == 'en'"
                  class="dropdown-item btn btn-outline-dark"
                  type="button"
                  @click="toggle('zh')"
                >
                  简体中文
                </button>
                <button
                  v-else
                  class="dropdown-item btn btn-outline-dark"
                  type="button"
                  @click="toggle('en')"
                >
                  English
                </button>
              </div>
            </li> -->
          </ul>
        </div>
      </nav>
    </div>
    <div class="head-widgets" :class="switchType == 'off' ? 'switch-off' : ''">
      <form>
        <div class="switch" @click="toggle">
          <input
            v-model="switchType"
            type="radio"
            id="on"
            name="status"
            value="on"
            :checked="switchType == 'on'"
          />
          <input
            v-model="switchType"
            type="radio"
            id="off"
            name="status"
            value="off"
            :checked="switchType == 'off'"
          />
          <span class="toggle-outside"
            ><span class="toggle-inside"></span
          ></span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: localStorage.getItem("language") || "en",
      switchType: "on",
    };
  },
  created() {},
  mounted() {
    // If CSS Custom Properties or Variables are supported
    if (!window.CSS && CSS.supports("color", "var(--primary)")) {
      //hide the switcher
      const switcherContainer = document.querySelectorAll(".head-widgets")[0];
      switcherContainer.style.display = "none";
      return;
    }
    let mode = localStorage.getItem("color-mode");
    if (mode == "light") {
      this.switchType = "off";
    } else {
      this.switchType = "on";
    }
    document.documentElement.setAttribute(
      "color-mode",
      localStorage.getItem("color-mode") || "dark"
    );
  },
  methods: {
    toggle() {
      if (this.switchType == "on") {
        this.switchType = "off";
        // Sets the custom html attribute
        document.documentElement.setAttribute("color-mode", "light");
        // Sets the user's preference in local storage
        localStorage.setItem("color-mode", "light");
      } else {
        this.switchType = "on";
        document.documentElement.setAttribute("color-mode", "dark");
        localStorage.setItem("color-mode", "dark");
      }
    },
  },
};
</script>
<style scoped lang="scss">
//Color palettes
$colors: (
  "surface": #303133,
  "primary": #fff,
);
@mixin color-var($property, $color) {
  #{$property}: map-get($colors, "#{$color}");
  #{$property}: var(--#{$color}, map-get($colors, "#{$color}"));
}
.header {
  position: sticky;
  top: 0;
  z-index: 99;
  padding: 16px 0;
  @include color-var(background-color, surface);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  opacity: .99;
  .switch-off {
    > form {
      transform: scaleX(-1);
    }
  }
  .logo {
    transform: scale(1) !important;
    opacity: 1 !important;
    display: flex;
    align-items: center;
    .logo-right {
      width: 183px;
      margin-right: 24px;
    }
    .logo-left {
      width: 64px;
      margin-right: 16px;
    }
    .logo-title {
      font-size: 24px;
      color: #fff;
    }
  }
  .navbar {
    padding: 0;
  }
  .navbar-nav {
    width: 100%;
    margin-right: 0 !important;
    display: flex;
    justify-content: flex-end;
    .wallet {
      border-radius: 6px;
      padding: 8px 12px;
      background: #33b364;
    }
  }
  .container .navbar-dark .navbar-nav .nav-link {
    margin: 0 6px;
    font-weight: 700;
    color: #fff;
    font-size: 14px;
    &:hover {
      color: rgba(255, 255, 255, 0.678);
    }
  }
}
//Toggle related styles
form {
  position: fixed;
  top: 4.5%;
  right: -3.1rem;
  z-index: 9;
  cursor: pointer;
  transition: 0.2s;
  .switch {
    position: relative;
    width: 6rem;
    height: 3rem;
    margin: 0 auto;
    font-size: 0;

    input {
      position: absolute;
      top: 0;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
      height: 3rem;
      width: 6rem;
      margin: 0;

      &:checked {
        z-index: 1;

        ~ .toggle-outside .toggle-inside {
          left: 0.25rem;
        }

        + label {
          opacity: 1;
          cursor: default;
        }
      }

      &:not(:checked) + label:hover {
        opacity: 0.5;
      }
    }

    .toggle-outside {
      height: 100%;
      border-radius: 2rem;
      padding: 0.25rem;
      overflow: hidden;
      transition: 0.5s ease all;
      background: #fff;
      position: absolute;
      width: 6rem;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    }

    .toggle-inside {
      border-radius: 5rem;
      background: #192432;
      position: absolute;
      transition: 0.25s ease all;
      height: 2.5rem;
      width: 2.5rem;

      &:before {
        font-family: "Font Awesome 5 Free";
        font-size: 1rem;
        font-weight: 900;
        content: "\f186";
        font-style: normal;
        color: #ffffff;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        position: absolute;
        z-index: 3;
        margin: 20% auto;
        left: 0;
        right: 0;
        text-align: center;
        color: #b2ebf2;
      }
    }

    input ~ input:checked ~ .toggle-outside .toggle-inside {
      left: 3.25rem;
      background-color: #fbc02d;
      &:before {
        content: "\f185";
        color: #ff5722;
      }
    }

    input#on:focus ~ .toggle-outside .toggle-inside {
      background-color: #b2ebf2;

      &:before {
        color: #192432;
      }
    }

    input#off:focus ~ .toggle-outside .toggle-inside {
      background-color: #ff5722;

      &:before {
        color: #fbc02d;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .header {
    .navbar-brand {
      flex: 1;
      .logo {
        .logo-title {
          display: inline-block;
          width: 100%;
          text-align: center;
        }
        .logo-right {
          display: none;
        }
      }
    }
    #navbarSupportedContent {
      margin-top: 12px;
      padding: 12px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.315);
    }
  }
}
@media screen and (max-width: 500px) {
  .header .navbar-brand .logo .logo-left {
    display: none !important;
  }
}
@media screen and (max-width: 692px) {
  form{
    top: 20%;
  }
}
</style>
