<template>
  <div id="footer" class="container">
    <div class="f-box row">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="col-lg-4 f-box-item"
      >
        <p class="f-box-label">{{ item.label }}</p>
        <ul class="list-group list-group-flush">
          <li
            v-for="ele in item.children"
            :key="ele.label"
            class="list-group-item"
          >
            <a :href="ele.jump" target="_blank">{{ ele.label }}</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- <i class="bi bi-envelope-fill"></i> -->
    <p class="email">
      To get in touch, please email
      <a class="link" href="mailto:dinosaur@dinosaurtoken.vip" target="_blank"
        >dinosaur@dinosaurtoken.vip</a
      >
    </p>
    <div class="f-box-icons">
      <a target="_blank" href="https://twitter.com/coin_dinosaur"
        ><i class="bi bi-twitter"></i
      ></a>
      <a target="_blank" href="https://t.me/DinosaurToken"
        ><i class="bi bi-telegram"></i
      ></a>
      <a target="_blank" href="https://neuralink.com/"
        ><i class="bi bi-signpost-fill"></i
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          label: "Crypto",
          children: [
            { label: "CoinGecko", jump: "http://www.coingecko.com" },
            { label: "Live Coin Watch", jump: "http://www.livecoinwatch.com" },
            { label: "CoinMarketCap", jump: "https://www.coinmarketcap.com" },
          ],
        },
        {
          label: "Exchange",
          children: [{ label: "UniSwap", jump: "https://uniswap.org/" }],
        },
        {
          label: "Partners",
          children: [
            { label: "Token Pocket", jump: "https://www.tokenpocket.pro/" },
            {
              label: "IM TOKEN",
              jump: "https://token.im/",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
//Color palettes
$colors: (
  "surface": #303133,
  "primary": #fff,
);
@mixin color-var($property, $color) {
  #{$property}: map-get($colors, "#{$color}");
  #{$property}: var(--#{$color}, map-get($colors, "#{$color}"));
}
#footer {
  padding: 60px 0;
  @include color-var(color, primary);
  .f-box {
    margin: 0;
    .f-box-item {
      margin-bottom: 30px;
      .f-box-label {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 15px;
        text-align: center;
      }
      .list-group {
        @include color-var(color, primary);
        .list-group-item {
          text-align: center;
          padding: 0;
          padding-bottom: 12px;
          background: transparent;
          font-size: 14px;
          border: none;
          > a {
            width: 100%;
            display: inline-block;
            @include color-var(color, primary);
            text-decoration: none;
            &:hover {
              font-weight: bold;
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }
  .email {
    margin: 10px 0;
    text-align: center;
    font-weight: bold;
    > span {
      color: #33b364;
    }
    .link {
      color: #33b364;
      text-decoration: none;
    }
  }
  .f-box-icons {
    margin-top: 30px;
    text-align: center;
    a {
      display: inline-block;
      @include color-var(color, primary);
      text-decoration: none;
      margin: 0 20px;
      font-size: 20px;
      &:hover {
        transform: scale(1.2);
        color: #33b364;
      }
    }
  }
}
</style>
