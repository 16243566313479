import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
let routes = [
    {
        path: '/',  // 路径
        component: () => import('@/views/index')
    },
    // {
    //     path: '/airdrop',  // 路径
    //     component: () => import('@/views/airdrop')
    // },
]
const router = new VueRouter({
    mode: 'history',
    routes // (缩写) 相当于 routes: routes
})

export default router